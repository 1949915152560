body {
  /* background-image: url('./ok.gif'); */
  /*background-image: url('./bg.jpg');*/
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
}

.title {
  font-size: 40px;
}

.centered {
  padding: 10px;
  font-size: 20px;
  text-align: center;
}

.init-icons {
  
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
}

.ico {
  display: flex;
  flex-direction: column;
  align-items: center; 
  color: rgb(60, 17, 17);
  padding: 15px; /* Increase padding */
  font-size: 0.9rem; /* Increase text size */
  
}




a {
  text-decoration: none;
  /* padding: 10px; */
}

.contact {
  display: grid;

}

.contact>a {
  display: grid !important;

}

.contact-container {
  
  display: flex;
  flex-direction: column;

}

.links {
  margin: 10px;

}

.links a,
.links a:visited,
.links a:hover,
.links a:active,
.links span {
  color: #0066cc;
  text-decoration: none;
}

.links a:hover,
.links span:hover {
  color: #004d99;
  text-decoration: underline;
}


.links>a {
  margin: 10px;
  padding: 10px;
}

.AboutMe{
  touch-action: manipulation;
  font-size: 20px
}

.Documents{
  touch-action: manipulation;
  display: flex;
  align-items: center; 
  color: rgb(60, 17, 17);
  padding: 20px;
}